import { Injectable } from '@angular/core';
import { Effect,Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Login, AuthActionTypes, Logout } from './auth.actions';
import { tap, map } from 'rxjs/operators';
import { defer, of } from 'rxjs';

@Injectable()
export class AuthEffects{

    @Effect()
    init$ = defer(() => {
        const token = localStorage.getItem("token");
        if (token) {
        return of(new Login({token}));
        }
        else {
        return <any>of(new Logout());
        }
    });
    @Effect({dispatch:false})
    login$=this.actions$.pipe(
        ofType<Login>(AuthActionTypes.LoginAction),
        tap(action=>localStorage.setItem("token",action.payload.token))
    );
    @Effect({dispatch:false})
    logout$= this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.LogoutAction),
        tap(action=>{
            localStorage.removeItem('token');
            this.router.navigate(['/']);
        })
    );



    constructor(private actions$: Actions, private router:Router) {


    }

}