import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { selectIsloggedIn } from 'src/app/modulos/login/reducers/auth.selectors';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateGuard implements CanActivate  {
  
  constructor(private _router:Router,private store:Store<AppState>){}
  
  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot):Observable<boolean>{

    return this.store.pipe(
      select(selectIsloggedIn),
      tap(loggedIn=>{
        if (!loggedIn) {
          this._router.navigateByUrl('/');
        }
      })
    );
    
  }
  
}
