import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '../../../utils/baseURI';
import { Observable } from 'rxjs';

@Injectable({providedIn:'root'})
export class AuthService{

    constructor(private _http:HttpClient){}

    login(email:string,pwd:string):Observable<any>{
        let url = BASE_URL + 'usuario/login';
        return this._http.post(url,{correo:email,pwd});
    }


}