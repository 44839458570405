import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { Usuario } from '../../usuario/model/usuario.model';
import { AuthActions, AuthActionTypes } from './auth.actions';


export interface AuthState {
  loggedIn:boolean,
  token:string
}

export const initialAuthState:AuthState={
  loggedIn:false,
  token:undefined
}

export function authReducer(state=initialAuthState,action:AuthActions):AuthState{
  switch(action.type){
    case AuthActionTypes.LogoutAction:
      return {
        loggedIn:false,
        token:undefined
      }
    case AuthActionTypes.LoginAction:
      return {
        loggedIn:true,
        token:action.payload.token
      }
    default:
    return state;
  }
}