import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticateGuard } from './services/guards/authenticate.guard';

const routes: Routes = [
  {path:'usuarios',loadChildren:'./modulos/usuario/usuario.module#UsuarioModule',canActivate:[AuthenticateGuard]},
  {path:'tarifas',loadChildren:'./modulos/tarifas/tarifas.module#TarifasModule',canActivate:[AuthenticateGuard]},
  {path:'**', redirectTo:'/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
