import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Login } from './reducers/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginForm:FormGroup;
  
  

  ngOnInit() {
    this.formInit();
  }

  

  login(){
    if(!this.loginForm.valid){
      return Swal.fire({
        title:"Error",
        text:"Campos incompletos",
        type:"error"
      })
    }
    this.authService.login(this.loginForm.value.correo,this.loginForm.value.pwd)
    .subscribe(
      (res:any)=>{
        this.store.dispatch(new Login({token:res.token}));
      },
      (err:any)=>{
        Swal.fire({
          title:"Error",
          text:"Correo o password incorrectos",
          type:"error"
        });
      }
    );
  }

  formInit(){
    this.loginForm = new FormGroup({
      correo:new FormControl('ketrujillo08@gmail.com',Validators.required),
      pwd:new FormControl('morris', Validators.required)
    });
  }
  constructor(private authService:AuthService,private store:Store<AppState>) {}

}
