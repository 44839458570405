import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { StoreModule } from '@ngrx/store';
import * as fromLogin from './reducers/auth.reducers';
import { AuthEffects } from './reducers/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { AuthService } from './services/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    StoreModule.forFeature('login', fromLogin.authReducer),
    EffectsModule.forFeature([AuthEffects]),
    HttpClientModule
  ]
})
export class LoginModule {
  static forRoot():ModuleWithProviders{
    return{
      ngModule:LoginModule,
      providers:[AuthService]
    }
  }
}
